.menu {
  list-style: none;
  padding: 0;

  &__item {
    display: flex;
    border-bottom: 1px solid $primary-light;
    align-items: center;

    &:first-child {
      border-top: 1px solid $primary-light;
    }
  }
}