//sizing
$baseline: 6px;

// colors:
$primary: #D4AF37;
$primary-light: rgba( $primary, .35 );
$grey: #333333;
$grey-light: #999999;
$white: #FFF;

$bg-color: rgba( $primary, .1 );

//fonts
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Slabo 27px', serif;
