.footer {
  background-color: $white;
  padding: $baseline * 4 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  box-shadow: 0px -3px 12px 0px rgba(0,0,0,0.05);

  &__title {
    letter-spacing: $baseline / 2;
    text-transform: uppercase;
    font-size: $baseline * 3;
    margin: 0;

    &--primary {
      color: $primary;
    }
  }

  &__allergies {
    margin-top: $baseline * 3;
  }

  &__allergies-title {
    color: $grey-light
  }
}