.course {
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
    cursor: pointer;
  }

  &:hover {

    .course__name {
      color: $primary;
    }
  }

  &__content {
    flex: 1;
    margin: 0 $baseline * 8;
  }

  &__name {
    font-family: $font-secondary;
    letter-spacing: 1px;
    font-size: 18px;
    margin-bottom: 0;
    transition: color 0.35s ease;

    &--active {
      color: $primary;
    }

  }

  &__description {
    font-size: 13px;
    color: $grey-light;
    margin-bottom: $baseline;
  }

  &__wrap-image {
    width: 200px;
    height: 150px;
    overflow: hidden;
    position: relative;
  }

  &__image {
    position: absolute;
    height: auto;
    width: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}