.confirmed-order {
  margin-bottom: $baseline * 10;

  &__title {
    letter-spacing: $baseline / 2;
    text-transform: uppercase;
    font-size: $baseline * 3;
    margin-bottom: $baseline * 5;
    text-align: center;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: block;
    padding: $baseline * 3 0;
    font-size: $baseline * 3;
    font-family: $font-secondary;
    border-bottom: 1px solid $primary-light;

    &:first-child {
      border-top: 1px solid $primary-light;
    }
  }
}