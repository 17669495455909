.allergies {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: inline-block;
    margin-left: $baseline * 3;
    font-size: 13px;

    &:first-child {
      margin-left: 0;
    }

    &--asterisk {
      &::before {
        content: '*'
      }
    }
  }

  &__wrap {
    margin: $baseline * 5 0 $baseline * 10 0;
    text-align: center;
  }

  &__title {
    font-size: 13px;
    color: $grey-light;
  }
}