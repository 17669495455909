@import url(https://fonts.googleapis.com/css?family=Roboto|Slabo+27px);
body {
  background-color: rgba(212, 175, 55, 0.1);
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  color: #333333;
  font-size: 14px; }

.button {
  background-color: transparent;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  padding: 9px 18px;
  cursor: pointer;
  border-color: #D4AF37;
  transition: 0.35s all ease; }
  .button:focus {
    outline: none; }
  .button:active {
    border-color: #D4AF37;
    box-shadow: none;
    border-style: solid; }
  .button:hover {
    color: #D4AF37; }
  .button--primary {
    background-color: #D4AF37;
    color: #333333; }
    .button--primary:hover {
      color: #FFF; }

.header {
  margin: 24px auto; }
  .header__label {
    display: block;
    margin: 0;
    line-height: 1; }

.page-title__primary {
  font-family: "Slabo 27px", serif;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: #D4AF37;
  font-size: 36px; }

.page-title__secondary {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px; }

.main-container {
  margin: 48px auto 180px auto; }

.menu {
  list-style: none;
  padding: 0; }
  .menu__item {
    display: flex;
    border-bottom: 1px solid rgba(212, 175, 55, 0.35);
    align-items: center; }
    .menu__item:first-child {
      border-top: 1px solid rgba(212, 175, 55, 0.35); }

.course {
  width: 100%; }
  .course__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
    cursor: pointer; }
  .course:hover .course__name {
    color: #D4AF37; }
  .course__content {
    flex: 1 1;
    margin: 0 48px; }
  .course__name {
    font-family: "Slabo 27px", serif;
    letter-spacing: 1px;
    font-size: 18px;
    margin-bottom: 0;
    transition: color 0.35s ease; }
    .course__name--active {
      color: #D4AF37; }
  .course__description {
    font-size: 13px;
    color: #999999;
    margin-bottom: 6px; }
  .course__wrap-image {
    width: 200px;
    height: 150px;
    overflow: hidden;
    position: relative; }
  .course__image {
    position: absolute;
    height: auto;
    width: 200px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .course__info {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.allergies {
  list-style: none;
  padding: 0;
  margin: 0; }
  .allergies__item {
    display: inline-block;
    margin-left: 18px;
    font-size: 13px; }
    .allergies__item:first-child {
      margin-left: 0; }
    .allergies__item--asterisk::before {
      content: '*'; }
  .allergies__wrap {
    margin: 30px 0 60px 0;
    text-align: center; }
  .allergies__title {
    font-size: 13px;
    color: #999999; }

.footer {
  background-color: #FFF;
  padding: 24px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.05); }
  .footer__title {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0; }
    .footer__title--primary {
      color: #D4AF37; }
  .footer__allergies {
    margin-top: 18px; }
  .footer__allergies-title {
    color: #999999; }

.confirmed-order {
  margin-bottom: 60px; }
  .confirmed-order__title {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center; }
  .confirmed-order__list {
    list-style: none;
    padding: 0;
    margin: 0; }
  .confirmed-order__item {
    display: block;
    padding: 18px 0;
    font-size: 18px;
    font-family: "Slabo 27px", serif;
    border-bottom: 1px solid rgba(212, 175, 55, 0.35); }
    .confirmed-order__item:first-child {
      border-top: 1px solid rgba(212, 175, 55, 0.35); }

.spice-level {
  list-style: none;
  margin: 0;
  padding: 0; }
  .spice-level__item {
    display: inline-block;
    margin-left: 6px; }
    .spice-level__item:first-child {
      margin-left: 0; }
  .spice-level__image {
    display: inline-block;
    width: 18px;
    height: 18px; }

